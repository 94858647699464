import React from "react";
import WorldFlags from "react-world-flags";
import { getRegionIcon, getRoleIcon } from "./utils/iconHelpers";
import unlockCardImage from "../../assets/UnlockCard.png";
const ProfileHeader = ({
  userProfile,
  mostPlayedChampion,
  topChampions,
  gameVersion,
}) => {
  const formatStatus = (status) => {
    switch (status) {
      case "EN_EQUIPO":
        return "EN EQUIPO";
      case "SIN_EQUIPO":
        return "SIN EQUIPO";
      default:
        return status;
    }
  };

  const backgroundStyle =
    mostPlayedChampion && gameVersion
      ? {
          backgroundImage: `url(https://ddragon.leagueoflegends.com/cdn/img/champion/splash/${mostPlayedChampion}_0.jpg)`,
        }
      : {};

  return (
    <div
      className="relative bg-cover bg-top h-80 text-white"
      style={backgroundStyle}
    >
      <div className="absolute inset-0 bg-gradient-to-t from-indigo-900 via-indigo-900/70 to-transparent"></div>
      <div className="relative z-10 h-full flex flex-col justify-between p-6">
        <div className="flex items-center">
            <img
              src={`https://cdn.discordapp.com/avatars/${userProfile.id}/${userProfile.avatar}.png`}
              alt="User Avatar"
              className="w-20 h-20 rounded-full border-4 border-yellow-500"
              onError={(e) => {
                e.target.src = "https://i.redd.it/j3t4cvgywd051.png";
              }}
            />
            <div className="ml-4">
              <h1 className="text-2xl font-bold">{userProfile.user}</h1>
              <p className="text-gray-300">
                Status: {formatStatus(userProfile.status)}
              </p>
            </div>


        </div>
        <div className="flex justify-between items-end">
          <div className="flex flex-col items-start">
            <span className="text-sm mb-2">
              Campeones más jugados en los últimos 20 juegos
            </span>
            <div className="flex space-x-2">
              {topChampions &&
                gameVersion &&
                topChampions.map((champion, index) => (
                  <div key={index} className="flex flex-col items-center">
                    <img
                      src={`https://ddragon.leagueoflegends.com/cdn/${gameVersion}/img/champion/${champion}.png`}
                      alt={champion}
                      className="w-12 h-12 rounded-full border-2 border-yellow-500"
                    />
                    <span className="text-xs mt-1">{champion}</span>
                  </div>
                ))}
            </div>
          </div>
          <div className="flex space-x-6">
            <div className="flex flex-col items-center">
              <WorldFlags code={userProfile.country} className="w-8 h-8" />
              <span className="text-sm mt-1">{userProfile.country}</span>
            </div>
            <div className="flex flex-col items-center">
              <img
                src={getRegionIcon(userProfile.region)}
                alt="Region"
                className="w-8 h-8"
              />
              <span className="text-sm mt-1">{userProfile.region}</span>
            </div>
            <div className="flex flex-col items-center">
              <img
                src={getRoleIcon(userProfile.role)}
                alt="Role"
                className="w-8 h-8"
              />
              <span className="text-sm mt-1">{userProfile.role}</span>
            </div>
            <div className="flex flex-col items-center">
              <span className="text-xl font-bold">{userProfile.coins}</span>
              <span className="text-sm mt-1">Coins</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProfileHeader;
