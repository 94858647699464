import React, { useState, useEffect, useRef } from 'react';
import { Bell, ChevronDown, LogOut, UserCircle, Users, CreditCard, Award, Coins } from 'lucide-react';
import { useNavigate, useLocation } from 'react-router-dom';
import axios from 'axios';
import { motion, AnimatePresence } from 'framer-motion';

const User = ({ data, user, setUserData, coins }) => {
  const [isDropdownVisible, setIsDropdownVisible] = useState(false);
  const [isNotificationsVisible, setIsNotificationsVisible] = useState(false);
  const [hasUnreadNotification, setHasUnreadNotification] = useState(false);
  const [notifications, setNotifications] = useState([]);
  const dropdownRef = useRef(null);
  const notificationsRef = useRef(null);
  const navigate = useNavigate();
  const location = useLocation();

  const image = `https://cdn.discordapp.com/avatars/${data.id}/${data.avatar}.png`;

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsDropdownVisible(false);
      }
      if (notificationsRef.current && !notificationsRef.current.contains(event.target)) {
        setIsNotificationsVisible(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  useEffect(() => {
    if (user && user.has_profile && data.in_team) {
      axios.get("/api/my-scrims-notifications/")
        .then((response) => {
          setNotifications(response.data);
          setHasUnreadNotification(response.data.some((notification) => !notification.is_read));
        })
        .catch((error) => {
          console.error("Error fetching notifications:", error);
        });
    }
  }, [user, data.in_team, location.pathname]);

  const toggleDropdown = () => setIsDropdownVisible(!isDropdownVisible);
  const toggleNotifications = () => setIsNotificationsVisible(!isNotificationsVisible);

  const handleLogout = () => {
    window.location.href = '/oauth2/logout';
  };

  const menuItems = [
    { label: 'Mi perfil', icon: UserCircle, onClick: () => navigate('/profile') },
    ...(user.has_profile && user.user_profile?.status === "EN_EQUIPO" ? [
      { label: 'Mi Equipo', icon: Users, onClick: () => navigate(`/league/equipos/${data.team_info?.team_name}`) },
      { label: 'Mis Scrims', icon: Award, onClick: () => navigate('/league/scrims/myteam') },
      { label: 'Scrims agendados', icon: CreditCard, onClick: () => navigate('/league/scrims/matches') },
      { label: 'Solicitudes de scrim recibidas', icon: Bell, onClick: () => navigate('/league/scrims/invitations-received') },
      { label: 'Solicitudes de scrim enviadas', icon: Bell, onClick: () => navigate('/league/scrims/invitations-sent') },
    ] : []),
    { label: 'Logout', icon: LogOut, onClick: handleLogout },
  ];

  return (
    <div className="flex items-center space-x-4 relative">
      {user && user.has_profile && (
        <>
          <div className="flex items-center space-x-2 bg-indigo-600 px-3 py-1 rounded-full text-white text-sm">
            <Coins size={16} className="text-yellow-300" />
            <span>{coins}</span>
          </div>
          <div className="relative cursor-pointer" onClick={toggleNotifications}>
            <Bell size={20} className={hasUnreadNotification ? "text-yellow-300" : "text-white"} />
            {hasUnreadNotification && (
              <div className="absolute -top-1 -right-1 w-2 h-2 bg-red-500 rounded-full"></div>
            )}
          </div>
        </>
      )}
      <div className="flex items-center space-x-2 cursor-pointer" onClick={toggleDropdown}>
        <img
          src={image}
          alt="User Avatar"
          className="w-8 h-8 rounded-full border-2 border-indigo-500"
          onError={(e) => {
            e.target.src = 'https://i.redd.it/j3t4cvgywd051.png';
          }}
        />
        <span className="text-white text-sm font-medium">{data.discord_tag}</span>
        <ChevronDown size={16} className="text-gray-400" />
      </div>
      <AnimatePresence>
        {isDropdownVisible && (
          <motion.div
            ref={dropdownRef}
            initial={{ opacity: 0, y: -10 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -10 }}
            transition={{ duration: 0.2 }}
            className="absolute right-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 divide-y divide-gray-100 focus:outline-none z-50"
            style={{ top: '100%' }}
          >
            <div className="py-1">
              {menuItems.map((item, index) => (
                <a
                  key={index}
                  href="#"
                  className="group flex items-center px-4 py-2 text-sm text-gray-700 hover:bg-indigo-50 hover:text-indigo-700"
                  onClick={(e) => {
                    e.preventDefault();
                    item.onClick();
                    setIsDropdownVisible(false);
                  }}
                >
                  <item.icon className="mr-3 h-5 w-5 text-gray-400 group-hover:text-indigo-500" aria-hidden="true" />
                  {item.label}
                </a>
              ))}
            </div>
          </motion.div>
        )}
      </AnimatePresence>
      <AnimatePresence>
        {isNotificationsVisible && (
          <motion.div
            ref={notificationsRef}
            initial={{ opacity: 0, y: -10 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -10 }}
            transition={{ duration: 0.2 }}
            className="absolute right-0 mt-2 w-80 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none z-50"
            style={{ top: '100%' }}
          >
            <div className="py-2 px-4">
              <h3 className="text-lg font-medium text-gray-900 mb-2">Notifications</h3>
              {notifications.length > 0 ? (
                notifications.map((notification, index) => (
                  <div key={index} className={`py-2 ${!notification.is_read ? 'bg-indigo-50' : ''}`}>
                    <p className="text-sm text-gray-700">{notification.message}</p>
                    <p className="text-xs text-gray-500 mt-1">{new Date(notification.created_at).toLocaleString()}</p>
                  </div>
                ))
              ) : (
                <p className="text-sm text-gray-700">No new notifications</p>
              )}
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};

export default User;