import React, { useState } from "react";
import { FaCoins } from "react-icons/fa";
import axios from "axios";
import Loader from "../../components/Loader";

export const ProductCard = ({
  id,
  title,
  description,
  price,
  image,
  endpoint,
  disabled,
}) => {
  const [modalOpen, setModalOpen] = useState(false);
  const [modalContent, setModalContent] = useState("");
  const [modalTitle, setModalTitle] = useState("");
  const [loading, setLoading] = useState(false);

  const handleBuy = async () => {
    if (endpoint !== "" && !disabled) {
      try {
        setLoading(true);
        const response = await axios.post(endpoint);
        setModalContent(response.data.detail);
        setModalTitle("Compra Exitosa");
        setModalOpen(true);
      } catch (error) {
        setModalContent(
          error.response.data.detail || "Error al realizar la compra"
        );
        setModalTitle("Error al realizar la compra");
        setModalOpen(true);
      } finally {
        setLoading(false);
      }
    }
  };

  const closeModal = () => {
    setModalOpen(false);
    setModalContent("");
    setModalTitle("");
    window.location.reload();
  };

  return (
    <div className={`bg-gray-800 rounded-lg overflow-hidden shadow-lg transition-all duration-300 flex flex-col h-full ${disabled ? "opacity-50" : "hover:shadow-yellow-500/50 hover:scale-105"}`}>
      <div className="h-48 overflow-hidden bg-gray-700">
        <img
          src={image}
          alt={title}
          className="w-full h-full object-contain p-4"
        />
      </div>
      <div className="p-6 flex flex-col flex-grow">
        <h3 className="text-xl font-semibold text-white mb-2">{title}</h3>
        <div className="flex items-center justify-center mb-4">
          <FaCoins className="text-yellow-500 mr-2" />
          <span className="text-white font-bold">{price}</span>
        </div>
        <p className="text-gray-300 text-sm mb-4 flex-grow">
          {description.split("\n").map((line, index) => (
            <React.Fragment key={index}>
              {line}
              <br />
            </React.Fragment>
          ))}
        </p>
        <button
          disabled={disabled || loading}
          className={`w-full px-4 py-2 rounded-md text-white font-semibold transition-colors duration-300 ${
            disabled
              ? "bg-gray-600 cursor-not-allowed"
              : "bg-yellow-500 hover:bg-yellow-600"
          }`}
          onClick={handleBuy}
        >
          {loading ? "Cargando..." : "Comprar"}
        </button>
      </div>
      {modalOpen && (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
          <div className="bg-gray-800 p-6 rounded-lg shadow-xl border-2 border-yellow-500 text-white max-w-md w-full">
            <h3 className={`font-bold text-xl mb-4 ${modalTitle.includes("Error") ? "text-red-500" : "text-green-500"}`}>
              {modalTitle}
            </h3>
            <p className="mb-6">{modalContent}</p>
            <button
              onClick={closeModal}
              className="w-full bg-yellow-500 hover:bg-yellow-600 text-white font-bold py-2 px-4 rounded-md transition-colors duration-300"
            >
              Cerrar
            </button>
          </div>
        </div>
      )}
      {loading && (
        <div className="fixed inset-0 z-50 flex justify-center items-center bg-black bg-opacity-50">
          <Loader />
        </div>
      )}
    </div>
  );
};