import React, { useState } from "react";
import { IoInformationCircleOutline } from "react-icons/io5";
import Container from "../../components/Container";
import { ProductCard } from "./ProductCard";
import DiscordRoleUpgradeImage from "../../assets/shop/Discord_role_upgrade.png";
import RulesetImage from "../../assets/shop/ruleset3.png";
import Ticket from "../../assets/shop/Ticket_Clash.png";
import TitleContainer from "../../components/TitleContainer";
import { ShoppingBag } from "lucide-react";

const Index = () => {
  const products = [
    {
      id: 2,
      title: "Ticket de Torneo",
      price: 2500,
      image: Ticket,
      endpoint: "/shop/buy/tournament-ticket/",
      disabled: false,
      description:
        "El ticket de torneo es para unirse a un torneo que no ha iniciado, dependiendo el torneo necesitarás más de un ticket para poder unirte (Objeto Consumible).",
    },
    {
      id: 3,
      title: "Ruleset Nivel 1",
      price: 2500,
      image: RulesetImage,
      endpoint: "/shop/buy/rule-set-tier-3/",
      disabled: false,
      description: `El ticket de reglas nivel 1 se intercambia por un ticket consumible que otorga uno de los siguientes beneficios:\n1.- Banear un campeón aleatorio.\n2.- Banear un hechizo de invocador de bajo nivel (Heal/Barrier/Ghost) al azar.`,
    },
    {
      id: 4,
      title: "Ruleset Nivel 2",
      price: 4000,
      image: RulesetImage,
      endpoint: "/shop/buy/rule-set-tier-2/",
      disabled: false,
      description: `El ticket de reglas nivel 2 se intercambia por un ticket consumible que otorga uno de los siguientes beneficios:\n1.- Banear un campeón a tu elección.\n2.- Banear un hechizo de invocador de bajo nivel (Heal/Barrier/Ghost) a tu elección.\n3.- Banear un hechizo al azar de nivel alto (Ignite, Extenuar).`,
    },
    {
      id: 5,
      title: "Ruleset Nivel 3",
      price: 10000,
      image: RulesetImage,
      endpoint: "/shop/buy/rule-set-tier-1/",
      disabled: false,
      description: `El ticket nivel 3 se intercambia por un ticket consumible que otorga uno de los siguientes beneficios:\n1.- Cancelar una regla del torneo.\n2.- Banear un campeón a tu elección.\n3.- Banear un hechizo de invocador de alto nivel (Ignite, Extenuar, Teleport) a tu elección.\n4.- Banear un ítem de alto nivel al azar (3000+ de costo ingame).`,
    },
    {
      id: 1,
      title: "Role Upgrade",
      price: "???",
      image: DiscordRoleUpgradeImage,
      endpoint: "",
      disabled: true,
      description: "Conseguir mejoras de rol en el servidor de Discord.",
    },
  ];

  const [showPopover, setShowPopover] = useState(false);

  return (
    <div className="min-h-screen bg-gray-900">
      <Container className="relative">
        <div className="flex flex-col gap-2 mt-4">
          <TitleContainer
            text="Shop"
            icon={<ShoppingBag className="h-6 w-6 text-white" />}
            color="bg-blue-600"
            flex="start"
          />
          <div className="absolute top-0 right-0 mt-4 mr-4">
            <button
              className="flex items-center text-xs font-semibold bg-gray-800 hover:bg-gray-700 text-white rounded-full px-3 py-1 transition duration-300"
              onMouseEnter={() => setShowPopover(true)}
              onMouseLeave={() => setShowPopover(false)}
            >
              <IoInformationCircleOutline className="mr-1" size={16} />
              ¿Cómo conseguir monedas?
            </button>
            {showPopover && (
              <div className="bg-gray-800 rounded-lg p-4 absolute top-10 right-0 z-10 w-48 text-xs text-white shadow-lg">
                <p className="mb-2">• Jugando scrims</p>
                <p className="mb-2">• Participando en el discord</p>
                <p>• Haciendo donaciones</p>
              </div>
            )}
          </div>

          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8">
            {products.map((product) => (
              <ProductCard
                key={product.id}
                id={product.id}
                title={product.title}
                description={product.description}
                price={product.price}
                image={product.image}
                endpoint={product.endpoint}
                disabled={product.disabled}
              />
            ))}
          </div>
        </div>
      </Container>
    </div>
  );
};

export default Index;
